import { NotificationType } from '@local/web-design-system';
import { isEmpty } from 'lodash-es';
import { Dispatch } from 'redux';
import { from as observableFrom, concatMap, mergeMap, toArray } from 'rxjs';
import { DEFAULT_FILTERS, CLIENT_SIDE_PAGINATION_LIMIT } from 'state-domains/constants';
import { FilterObject } from 'state-domains/domain/drillhole';
import { eventsShim } from 'state-domains/domain/events';
import {
    addJobCreationSuccessSnackbar,
    createFilterFromSearchTerm,
} from 'state-domains/domain/utils';
import { getBoundingBoxGeoJSONQuadrants } from 'src/components/Drillhole/DrillholeMap';
import { MapLayer } from 'src/components/Drillhole/DrillholeMap/DrillholeMap.types';
import { UserPortalState } from 'src/state';

import {
    LIST_PROJECTS,
    SET_SEARCH_TERM,
    PIN_PROJECT,
    ADD_SNACKBAR_NOTIFICATION,
    MAP_VIEW_STATE,
    LOAD_OVERVIEW_COLLARS_WITH_COUNT,
    CLEAR_OVERVIEW_COLLARS,
    CLEAR_PROJECT_LOAD_STATE,
    IMPORT_COLLARS,
    CLEAR_IMPORT_COLLARS,
} from '../../../types/actionTypes';
import { typeComplete, typeFail, typePending } from '../../../utils';
import { selectors as userSelectors } from '../../user/state/selectors';
import { projectShim } from '../shim';
import { getFiltersForOverviewCollars, getOverviewMapBounds } from '../utils';

import { selectors as projectSelectors } from './selectors';

const loadProjectList = (id?: string) => (dispatch: Dispatch, getState: any) => {
    dispatch({ type: typePending(LIST_PROJECTS) });
    observableFrom([
        id ? projectShim.loadProject : projectShim.loadProjectList,
        projectShim.loadProjectFileGroups,
    ])
        .pipe(concatMap((action) => action(id ?? '')))
        .pipe(toArray())
        .subscribe({
            next: (responses: any[]) => {
                const projectsList = id ? [responses[0]] : responses[0];
                const projectFileGroups = responses[1];
                const userId = getState().user.id;

                if (projectsList) {
                    dispatch({
                        type: typeComplete(LIST_PROJECTS),
                        payload: { projectsList, userId, projectFileGroups },
                    });
                } else {
                    dispatch({
                        type: ADD_SNACKBAR_NOTIFICATION,
                        payload: {
                            type: NotificationType.ERROR,
                            message: 'Projects list could not be loaded',
                        },
                    });
                }
            },
            error: (error: Error) => {
                dispatch({ type: typeFail(LIST_PROJECTS), payload: { error } });

                if (!id) {
                    dispatch({
                        type: ADD_SNACKBAR_NOTIFICATION,
                        payload: { type: NotificationType.ERROR, message: error },
                    });
                }
            },
        });
};

const setPinProject = (projectId: string, pin: boolean) => (dispatch: Dispatch) => {
    dispatch({ type: typePending(PIN_PROJECT) });
    return projectShim.setPinProject(projectId, pin).subscribe({
        next: () => {
            dispatch({
                type: typeComplete(PIN_PROJECT),
                payload: { projectId, pin },
            });
        },
        error: (error: any) => {
            dispatch({ type: typeFail(PIN_PROJECT), payload: { error } });
            dispatch({
                type: ADD_SNACKBAR_NOTIFICATION,
                payload: { type: NotificationType.ERROR, message: error },
            });
        },
    });
};

const getFilters = (projId: string, userId: string, subscriptionId: string) => {
    const filters = getFiltersForOverviewCollars(projId, userId, subscriptionId);
    return isEmpty(filters) ? DEFAULT_FILTERS : filters;
};

const { currentUserId } = userSelectors;
const { getSearchTerm } = projectSelectors;
export const loadOverviewCollarsWithCount =
    (projectId: string) => (dispatch: any, getState: any) => {
        dispatch({ type: typePending(LOAD_OVERVIEW_COLLARS_WITH_COUNT) });
        const allState = getState() as UserPortalState;
        const userId = currentUserId(allState);
        const searchTerm = getSearchTerm(allState);
        const currentSubscriptionId = allState.user.selected.id;
        const dhFilters = getFilters(projectId, userId, currentSubscriptionId);
        const filters = (dhFilters.filters as FilterObject[]) ?? [];
        if (searchTerm) filters.push(createFilterFromSearchTerm(searchTerm) as any);
        const boundingBox = getOverviewMapBounds(projectId, userId, currentSubscriptionId);
        const coordinates = getBoundingBoxGeoJSONQuadrants(boundingBox);

        const subscription = observableFrom([
            {
                action: projectShim.loadProjectOverviewCollarsCount,
                coordinates,
            },
            {
                action: projectShim.loadProjectOverviewCollars,
                coordinates,
            },
        ]);
        return subscription
            .pipe(
                mergeMap(
                    (subShim: { action: Function; coordinates: number[][] }) =>
                        subShim.action(
                            projectId,
                            dhFilters.activities as string[],
                            dhFilters.state as string[],
                            filters,
                            subShim.coordinates,
                        ),
                    5,
                ),
            )
            .pipe(toArray())
            .subscribe({
                next: (response: any) => {
                    dispatch({
                        type: typeComplete(LOAD_OVERVIEW_COLLARS_WITH_COUNT),
                        payload: response,
                    });
                },
                error: (error: any) => {
                    dispatch({
                        type: typeFail(LOAD_OVERVIEW_COLLARS_WITH_COUNT),
                        payload: { error },
                    });
                    dispatch({
                        type: ADD_SNACKBAR_NOTIFICATION,
                        payload: { type: NotificationType.ERROR, message: error },
                    });
                },
            });
    };

const setSearchTerm =
    (searchTerm: string, offset = 0, limit: number = CLIENT_SIDE_PAGINATION_LIMIT) =>
    (dispatch: Dispatch) =>
        dispatch({
            type: SET_SEARCH_TERM,
            payload: { searchTerm, offset, limit },
        });

const updateMapLayer = (mapType: MapLayer) => (dispatch: Dispatch) => {
    dispatch({
        type: MAP_VIEW_STATE,
        payload: { mapType },
    });
};

const clearOverviewCollars = () => (dispatch: Dispatch) => {
    dispatch({
        type: CLEAR_OVERVIEW_COLLARS,
    });
};

const clearProjectLoadState = () => (dispatch: Dispatch) => {
    dispatch({
        type: typeComplete(CLEAR_PROJECT_LOAD_STATE),
    });
};

const handleImportJobSuccess = (result: any, dispatch: Dispatch) => {
    eventsShim.updateJob({ id: result.jobs[0].id, filesUploaded: true }).subscribe({
        next: () => {
            dispatch({ type: typeComplete(IMPORT_COLLARS) });
            addJobCreationSuccessSnackbar(dispatch);
        },
        error: (error: any) => dispatch({ type: typeFail(IMPORT_COLLARS), payload: error }),
    });
};

const importCollars = (payload: any, fileMap: Record<string, File>) => (dispatch: Dispatch) => {
    dispatch({ type: typePending(IMPORT_COLLARS) });
    return projectShim.importCollars(payload).subscribe({
        next: (result: any) => {
            observableFrom(
                Object.entries(result.presignedUrls as Record<string, string>).map(
                    ([key, value]) => ({
                        url: value,
                        file: fileMap[key],
                    }),
                ),
            )
                .pipe(mergeMap((x) => projectShim.uploadFilesForImport(x.url, x.file)))
                .pipe(toArray())
                .subscribe({
                    next: () => handleImportJobSuccess(result, dispatch),
                    error: (error: any) =>
                        dispatch({ type: typeFail(IMPORT_COLLARS), payload: error }),
                });
        },
        error: (error: any) => {
            dispatch({ type: typeFail(IMPORT_COLLARS), payload: error });
            dispatch({
                type: ADD_SNACKBAR_NOTIFICATION,
                payload: { type: NotificationType.ERROR, message: error },
            });
        },
    });
};

const clearImportCollarState = () => (dispatch: Dispatch) =>
    dispatch({ type: CLEAR_IMPORT_COLLARS });

export const actions = {
    loadProjectList,
    setSearchTerm,
    setPinProject,
    updateMapLayer,
    loadOverviewCollarsWithCount,
    clearOverviewCollars,
    clearProjectLoadState,
    importCollars,
    clearImportCollarState,
};
